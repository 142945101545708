
import shopify from '../../images/integration-logo-images/shopify.png'
import wordpress from '../../images/integration-logo-images/wordpress.png'
import mailchimp from '../../images/integration-logo-images/mailchimp.png'
import mailerlite from '../../images/integration-logo-images/mailerlite.png'
import sendgrid from '../../images/integration-logo-images/sendgrid.png'
import sendinblue from '../../images/integration-logo-images/sendinblue.png'
import sendloop from '../../images/integration-logo-images/sendloop.png'
import magento from '../../images/integration-logo-images/magento.png'
import webflow from '../../images/integration-logo-images/webflow.png'
import weebly from '../../images/integration-logo-images/weebly.png'
import drupal from '../../images/integration-logo-images/drupal.png'
import joomla from '../../images/integration-logo-images/joomla.png'
import wix from '../../images/integration-logo-images/wix.png'
import googletagmanager from '../../images/integration-logo-images/googletagmanager.png'
import opencart from '../../images/integration-logo-images/opencart.png'
import bigcommerce from '../../images/integration-logo-images/bigcommerce.png'
import bigcartel from '../../images/integration-logo-images/bigcartel.png'
import woocommerce from '../../images/integration-logo-images/woocommerce.png'
import instapage from '../../images/integration-logo-images/instapage.png'
import prestashop from '../../images/integration-logo-images/prestashop.png'
import volusion from '../../images/integration-logo-images/volusion.png'
import squarespace from '../../images/integration-logo-images/squarespace.png'
import visualcomposer from '../../images/integration-logo-images/visualcomposer.png'
import textpattern from '../../images/integration-logo-images/textpattern.png'
import dnnsoftware from '../../images/integration-logo-images/dnnsoftware.png'
import octobercms from '../../images/integration-logo-images/octobercms.png'
import vercel from '../../images/integration-logo-images/vercel.png'
import clickfunnels from '../../images/integration-logo-images/clickfunnels.png'
import netlify from '../../images/integration-logo-images/netlify.png'
import subrion from '../../images/integration-logo-images/subrion.png'
import yiiframework from '../../images/integration-logo-images/yiiframework.png'
import craft from '../../images/integration-logo-images/craft.png'
import silverstripe from '../../images/integration-logo-images/silverstripe.png'
import umbraco from '../../images/integration-logo-images/umbraco.png'
import vuejs from '../../images/integration-logo-images/vuejs.png'
import angularjs from '../../images/integration-logo-images/angularjs.png'
import ghost from '../../images/integration-logo-images/ghost.png'
import carrd from '../../images/integration-logo-images/carrd.png'
import graphcms from '../../images/integration-logo-images/graphCMS.png'
import jimdo from '../../images/integration-logo-images/jimdo.png'
import blogger from '../../images/integration-logo-images/blogger.png'
import typo3 from '../../images/integration-logo-images/typo3.png'
import expressionengine from '../../images/integration-logo-images/expressionengine.png'
import progresssitefinity from '../../images/integration-logo-images/progresssitefinity.png'
import kirim from '../../images/integration-logo-images/kirim.png'


export const IntegrationProductsConstants = [
    {
        productName: "Shopify",
        image: shopify,
        alt: "Shopify Logo",
        route: 'shopify'
    },
    {
        productName: "Wordpress",
        image: wordpress,
        alt: "Wordpress Logo",
        route: 'wordpress'
    },
    {
        productName: "MailChimp",
        image: mailchimp ,
        alt: "MailChimp Logo",
        route: 'mailchimp'
    },
    {
        productName: "Mailerlite",
        image: mailerlite,
        alt: "Mailerlite Logo",
        route: 'mailerlite'
    },
    {
        productName: "SendGrid",
        image: sendgrid ,
        alt: "SendGrid Logo",
        route: 'sendgrid'
    },
    {
        productName: "Sendinblue",
        image: sendinblue ,
        alt: "Sendinblue Logo",
        route: 'sendinblue'
    },
    {
        productName: "Sendloop",
        image: sendloop ,
        alt: "Sendloop Logo",
        route: 'sendloop'
    },
    {
        productName: "Magento",
        image: magento ,
        alt: "Magento Logo",
        route: 'magento'
    },
    {
        productName: "Webflow",
        image: webflow ,
        alt: "Webflow Logo",
        route: 'webflow'
    },
    {
        productName: "Weebly",
        image: weebly ,
        alt: "Weebly Logo",
        route: 'weebly'
    },
    {
        productName: "Drupal",
        image: drupal ,
        alt: "Drupal Logo",
        route: 'drupal'
    },
    {
        productName: "Joomla",
        image: joomla ,
        alt: "Joomla Logo",
        route: 'joomla'
    },
    {
        productName: "Wix",
        image: wix ,
        alt: "Wix Logo",
        route: 'wix'
    },
    {
        productName: "Google Tag Manager",
        image: googletagmanager,
        alt: "Google Tag Manager Logo",
        route: 'google-tag-manager'
    },
    {
        productName: "Opencart",
        image: opencart ,
        alt: "Opencart Logo",
        route: 'opencart'
    },
    {
        productName: "BigCommerce",
        image: bigcommerce,
        alt: "BigCommerce Logo",
        route: 'bigcommerce'
    },
    {
        productName: "Big Cartel",
        image: bigcartel ,
        alt: "Big Cartel Logo",
        route: 'big-cartel'
    },
    {
        productName: "WooCommerce",
        image: woocommerce ,
        alt: "WooCommerce Logo",
        route: 'woocommerce'
    },
    {
        productName: "Instapage",
        image: instapage,
        alt: "Instapage Logo",
        route: 'instapage'
    },
    {
        productName: "Prestashop",
        image: prestashop ,
        alt: "Prestashop Logo",
        route: 'prestashop'
    },
    {
        productName: "Volusion",
        image: volusion,
        alt: "Volusion Logo",
        route: 'volusion'
    },
    {
        productName: "squarespace",
        image: squarespace ,
        alt: "Squarespace Logo",
        route: 'squarespace'
    },
    {
        productName: "Visual Composer",
        image: visualcomposer ,
        alt: "Visual Composer Logo",
        route: 'visual-composer'
    },
    {
        productName: "Textpattern",
        image: textpattern ,
        alt: "Textpattern Logo",
        route: 'textpattern'
    },
    {
        productName: "DNN Software",
        image: dnnsoftware ,
        alt: "DNN Software Logo",
        route: 'dnn-software'
    },
    {
        productName: "October CMS",
        image: octobercms ,
        alt: "October CMS Logo",
        route: 'october-cms'
    },
    {
        productName: "Vercel",
        image: vercel ,
        alt: "Vercel Logo",
        route: 'vercel'
    },
    {
        productName: "ClickFunnels",
        image: clickfunnels,
        alt: "ClickFunnels Logo",
        route: 'clickfunnels'
    },
    {
        productName: "Netlify",
        image: netlify ,
        alt: "Netlify Logo",
        route: 'netlify'
    },
    {
        productName: "Subrion",
        image: subrion ,
        alt: "Subrion Logo",
        route: 'subrion'
    },
    {
        productName: "Yii Framework",
        image: yiiframework ,
        alt: "Yii Framework Logo",
        route: 'yii-framework'
    },
    {
        productName: "Craft",
        image: craft,
        alt: "Craft Logo",
        route: 'craft'
    },
    {
        productName: "Silverstripe",
        image: silverstripe,
        alt: "Silverstripe Logo",
        route: 'silverstripe'
    },
    {
        productName: "Umbraco",
        image:umbraco ,
        alt: "Umbraco Logo",
        route: 'umbraco'
    }, {
        productName: "Vue.js",
        image: vuejs,
        alt: "Vue.js Logo",
        route: 'vuejs'
    },
    {
        productName: "AngularJS",
        image: angularjs ,
        alt: "AngularJS Logo",
        route: 'angularjs'
    },
    {
        productName: "Ghost",
        image: ghost,
        alt: "Ghost Logo",
        route: 'ghost'
    },
    {
        productName: "Carrd",
        image: carrd ,
        alt: "Carrd Logo",
        route: 'carrd'
    },
     {
        productName: "GraphCMS",
        image: graphcms ,
        alt: "GraphCMS Logo",
        route: 'graphcms'
    },
     {
        productName: "Jimdo",
        image: jimdo ,
        alt: "Jimdo Logo",
        route: 'jimdo'
    }, 
    {
        productName: "Blogger",
        image: blogger,
        alt: "Blogger Logo",
        route: 'blogger'
    }, 
    {
        productName: "Typo3",
        image: typo3,
        alt: "Typo3 Logo",
        route: 'typo3'
    }, 
    {
        productName: "Expression Engine",
        image: expressionengine ,
        alt: "Expression Engine Logo",
        route: 'expression-engine'
    },
    {
        productName: "Progress Sitefinity",
        image: progresssitefinity ,
        alt: "Progress Sitefinity",
        route: 'progress-sitefinity'
    },
    {
        productName: "Kirim",
        image: kirim ,
        alt: "Kirim",
        route: 'kirim'
    },
]